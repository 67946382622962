import { lazy } from 'react';
import appConfig from 'config/app';

const MyInfoCallback = lazy(() => import('../user/MyInfoCallback'));
const Staking = lazy(() => import('../staking/StakingPage'));
const Login = lazy(() => import('../views/Pages/Authentication/Login'));
const Register = lazy(() => import('../views/Pages/Authentication/Register'));
const Welcome = lazy(() => import('../views/Pages/Authentication/Welcome'));
const ClosedRegistration = lazy(() => import('../views/Pages/Authentication/ClosedRegistration'));
const RegisterSuccess = lazy(() => import('../views/Pages/Authentication/RegisterSuccess'));
const ForgetPassword = lazy(() => import('../views/Pages/Authentication/ForgetPassword'));
const UserProfileSettings = lazy(() => import('../user/SettingsPage'));
const VerifyEmail = lazy(() => import('../views/Pages/Authentication/VerifyEmail'));
const ResetPassword = lazy(() => import('../views/Pages/Authentication/ResetPassword'));
const ForgetPasswordSuccess = lazy(() => import('../views/Pages/Authentication/ForgetPasswordSuccess'));
const Wallets = lazy(() => import('../wallet/WalletPage'));
const Transactions = lazy(() => import('../transactions/TransactionPage'));
const TermsAndConditions = lazy(() => import('../views/Pages/Root/TermsAndConditions/TermsAndConditions'));
const TermsAndConditionsWelcome = lazy(() => import('../views/Pages/Root/TermsAndConditionsWelcome'));
const WithdrawalAction = lazy(() => import('../views/Pages/Withdrawal/WithdrawalAction'));
const Referral = lazy(() => import('../views/Pages/Root/Referral'));
const LiquidityMiningPage = lazy(() => import('../liquidity-mining/LiquidityMiningPage'));
const DisableAccountPage = lazy(() => import('auth/DisableAccount'));
const LearnEarnPage = lazy(() => import('learn-earn/LearnEarnPage'));
const GoogleAuthCallback = lazy(() => import('../auth/GoogleAuthCallback'));
const AppleAuthCallback = lazy(() => import('../auth/AppleAuthCallback'));
const FacebookAuthCallback = lazy(() => import('../auth/FacebookAuthCallback'));
const SingleSignonRegister = lazy(() => import('../auth/SingleSignonRegister'));
const SingleSignonLogin = lazy(() => import('../auth/SingleSignonLogin'));
const YieldVault = lazy(() => import('yield-vault/YieldVault'));
const Bundle = lazy(() => import('bundle/Bundle'));
const IncomingDepositsPage = lazy(() => import('incoming-deposits/IncomingDepositsPage'));
const VerifyCountryOfResidence = lazy(() => import('../views/Pages/PII/VerifyCountryOfResidence'));
const VerifyPhoneNumber = lazy(() => import('../views/Pages/PII/VerifyPhoneNumber'));
const VerifyInformation = lazy(() => import('../views/Pages/PII/VerifyInformation'));
const Campaigns = lazy(() => import('../views/Pages/Root/Campaigns'));
const RazerLoginSuccess = lazy(() => import('../views/Pages/Root/Campaigns/RazerLoginSuccessPage'));
const ProofOfReservesPage = lazy(() => import('../views/Pages/Root/ProofOfReserves/ProofOfReservesPage'));
const SubscriptionPage = lazy(() => import('../subscription/SubscriptionPage'));
const TermsAndConditionsReferralPromo2023 = lazy(() => import('../views/Pages/Root/TermsAndConditionsReferralPromo2023'));
const Lending = lazy(() => import('lending/LendingYieldProduct'));

/*
  path (required)                       : routing path
  component (required)                  : component to render
  name                                  : route name for redirection
  like                                  : opposite of exact
  isPublic                              : Authentication is optional
  isAuthenticatedRestricted             : Authentication user can't access
  authenticatedRestrictedRedirection    : If user is authenticated, redirect to this or to default
*/

export interface Route {
  path: string;
  component: React.ReactNode;
  name: string;
  title?: string;
  description?: string;
  like?: boolean;
  isPublic?: boolean;
  isAuthenticatedRestricted?: boolean;
  authenticatedRestrictedRedirection?: boolean;
  isWithdrawalOnlyUserRestricted?: boolean;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  ogUrl?: string;
}

const routes: Route[] = [
  {
    path: '/proof-of-reserves',
    name: 'Proof of Reserves',
    component: ProofOfReservesPage,
    isPublic: true,
  },
  {
    path: '/staking',
    name: 'Staking',
    component: Staking,
    isWithdrawalOnlyUserRestricted: true,
    isPublic: true,
  },
  {
    path: '/yield-vault',
    name: 'YieldVault',
    component: YieldVault,
    isWithdrawalOnlyUserRestricted: true,
    isPublic: true,
  },
  {
    path: '/lending',
    name: 'Lending',
    component: Lending,
    isWithdrawalOnlyUserRestricted: true,
    isPublic: true,
  },
  {
    path: '/bundle/:id',
    name: 'bundle',
    component: Bundle,
    isWithdrawalOnlyUserRestricted: true,
    isPublic: true,
  },
  {
    path: '/incoming-deposits',
    name: 'IncomingDeposits',
    component: IncomingDepositsPage,
    isWithdrawalOnlyUserRestricted: true,
    isPublic: false,
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms and Conditions',
    component: TermsAndConditions,
    isPublic: true,
    title: 'Terms and Conditions',
  },
  {
    path: '/terms-and-conditions-welcome',
    name: 'Terms and Conditions for WELCOME50 Promotion',
    component: TermsAndConditionsWelcome,
    isPublic: true,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    isAuthenticatedRestricted: true,
    isPublic: true,
    title: 'Login',
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    isAuthenticatedRestricted: true,
    isPublic: true,
    title: 'Sign up',
  },
  {
    path: '/closed',
    name: 'Register',
    component: ClosedRegistration,
    isAuthenticatedRestricted: true,
    isPublic: true,
    title: 'Sign up',
  },
  {
    path: '/email-verification/email/:instantSend?',
    name: 'Register Success',
    component: RegisterSuccess,
    isAuthenticatedRestricted: true,
    isPublic: true,
  },
  {
    path: '/verify/:userEmail/:token',
    name: 'Verify User',
    component: VerifyEmail,
    isAuthenticatedRestricted: false,
    isPublic: true,
  },
  {
    path: '/disable-account/:token',
    name: 'Disable account',
    component: DisableAccountPage,
    isAuthenticatedRestricted: false,
    isPublic: true,
  },
  {
    path: '/reset-password/:token',
    name: 'Reset Password',
    component: ResetPassword,
    isAuthenticatedRestricted: true,
    isPublic: true,
  },
  {
    path: '/forget-password',
    name: 'Forget Password',
    component: ForgetPassword,
    isAuthenticatedRestricted: true,
    isPublic: true,
  },
  {
    path: '/forget-password-success',
    name: 'Forget Password Success',
    component: ForgetPasswordSuccess,
    isAuthenticatedRestricted: true,
    isPublic: true,
  },
  {
    path: '/me/:subPage?',
    name: 'User Profile Settings',
    component: UserProfileSettings,
    title: 'Profile',
  },
  {
    path: '/wallets',
    name: 'Wallets',
    component: Wallets,
    title: 'Wallets',
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: Transactions,
    title: 'Transactions',
  },
  {
    path: '/withdrawal/:id/:code/:action',
    name: 'Withdrawal Action',
    component: WithdrawalAction,
  },
  {
    path: '/referral',
    name: 'Referral',
    component: Referral,
    isWithdrawalOnlyUserRestricted: true,
    isPublic: true,
  },
  {
    path: '/liquidity-mining',
    name: 'Liquidity Mining',
    component: LiquidityMiningPage,
    isWithdrawalOnlyUserRestricted: true,
    isPublic: true,
  },
  // {
  //   path: '/freezer',
  //   name: 'Freezer',
  //   component: FreezerPage,
  //   isPublic: true,
  // },
  {
    path: '/myinfo-callback',
    name: 'MyInfoCallback',
    component: MyInfoCallback,
  },
  {
    path: '/learn',
    name: 'Learn And Earn',
    component: LearnEarnPage,
    isWithdrawalOnlyUserRestricted: true,
    isPublic: true,
  },
  {
    path: '/google/auth-callback',
    name: 'GoogleAuthCallback',
    component: GoogleAuthCallback,
    isAuthenticatedRestricted: true,
    isPublic: true,
  },
  {
    path: '/facebook/auth-callback',
    name: 'FacebookAuthCallback',
    component: FacebookAuthCallback,
    isAuthenticatedRestricted: true,
    isPublic: true,
  },
  {
    path: '/apple/auth-callback',
    name: 'AppleAuthCallback',
    component: AppleAuthCallback,
    isAuthenticatedRestricted: true,
    isPublic: true,
  },
  {
    path: '/register/sso',
    name: 'Single Signon Register',
    component: SingleSignonRegister,
    isAuthenticatedRestricted: true,
    isPublic: true,
  },
  {
    path: '/login/sso',
    name: 'Single Signon Login',
    component: SingleSignonLogin,
    isAuthenticatedRestricted: true,
    isPublic: true,
  },
  {
    path: '/verify-country',
    name: 'Verify Country Of Residence',
    component: VerifyCountryOfResidence,
  },
  {
    path: '/verify-phone',
    name: 'Verify Phone Number',
    component: VerifyPhoneNumber,
  },
  {
    path: '/verify-identity',
    name: 'Verify Identity',
    component: VerifyInformation,
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome,
    isAuthenticatedRestricted: true,
    isPublic: true,
    title: 'Welcome',
  },
  {
    path: '/razer',
    name: 'Campaign Razer Silver',
    component: Campaigns,
    isPublic: true,
  },
  {
    path: '/razer/success',
    name: 'Campaign Razer Silver Login Success',
    component: RazerLoginSuccess,
  },
  {
    // used for Mobile App
    path: '/terms-and-conditions-referral-promo-2023',
    name: 'Terms and Conditions for Referral Promo 2023',
    component: TermsAndConditionsReferralPromo2023,
    isPublic: true,
  },
];

if (appConfig.SUBSCRIPTION_SERVICE) {
  routes.push(
    {
      path: '/elite',
      name: 'ELITE',
      component: SubscriptionPage,
      isPublic: true,
    },
  );
}

export default routes;
